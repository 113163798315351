import { Col, Row } from "antd";

export const Home = () => {
  return (
    <div className="main">
      <Row gutter={[18, 18]}>
        <Col span={12}>
          <p>Contact Info</p>
          <ul>
            <li>Water Supplier:</li>
            <li>Plants:</li>
            <li>Maid</li>
            <li>Maintenace</li>
          </ul>
          <h1>Home</h1>
        </Col>
      </Row>
    </div>
  );
};
