import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// import { NavBar } from "./components/navigation/navBar";
import { LandingPage } from "./pages/LandingPage";
import ScrollToTop from "./utils/ScrollToTop";
import { NotFound } from "./utils/notFound";

import { Assets } from "./pages/Assets";
import { ComingSoon } from "./pages/ComingSoon";
import { Home } from "./pages/Home";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route exact path="/site" element={<LandingPage />} />
        <Route exact path="/" element={<ComingSoon />} />
        <Route exact path="/hq-home" element={<Home />} />

        {/* Utils links */}
        <Route exact path="/assets" element={<Assets />} />

        <Route exact path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
