import { Col, Row } from "antd";

import wifi from "../images/qr-code-wifi.png";
// import { Link } from "react-router-dom";
export const ComingSoon = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Row
        gutter={[18, 18]}
        style={{ textAlign: "center", margin: "4px", maxWidth: "500px" }}
      >
        <Col span={24}>
          <img
            alt="logo"
            src={wifi}
            style={{ width: "100%", maxWidth: "500px" }}
          />
          <p>SLT-Fiber-2.4G</p>
        </Col>
        {/* <Col span={12}>
          <Link to={"/hq-home"}>
            <Button>Home</Button>
          </Link>
        </Col>
        <Col span={12}>
          <Link to={"/hq-studio"}>
            <Button>Studio</Button>
          </Link>
        </Col> */}
      </Row>
    </div>
  );
};
