import { Button, Card, Col } from "antd";
import logo from "../images/hq-logo.png";
import { PageLayout } from "../components/layouts/pageLayout";

export const LandingPage = () => {
  return (
    <PageLayout>
      <Col span={24} style={{ textAlign: "center" }}>
        <img
          alt="logo"
          src={logo}
          style={{ width: "100%", maxWidth: "500px" }}
        />
      </Col>

      <Col style={{ textAlign: "center" }}>
        <Card>
          <p>
            {
              "Create Hub simplifies landing page creation with customizable templates and an intuitive interface. Craft captivating pages optimized for conversions effortlessly. Say goodbye to complex coding and design struggles – focus on engaging your audience and achieving your goals. Join marketers worldwide in elevating your online presence with Create Hub. Experience professional-grade landing pages without the hassle. Start your journey to online excellence today!"
            }
          </p>
        </Card>
      </Col>

      <Col
        span={24}
        style={{ textAlign: "center", fontFamily: "sundayAfternoon" }}
      >
        <Button
          href="https://www.linkedin.com/"
          rel="noreferrer"
          target="_blank"
          style={{
            color: "#E99923",
            fontFamily: "sundayAfternoon",
          }}
        >
          {" "}
          Linkedin
        </Button>
        <Button
          href="https://www.instagram.com/"
          rel="noreferrer"
          target="_blank"
          style={{
            color: "#E99923",
            marginLeft: "4px",
            fontFamily: "sundayAfternoon",
          }}
        >
          {"Instagram"}
        </Button>
        <Button
          href="https://www.tiktok.com/"
          rel="noreferrer"
          target="_blank"
          style={{
            color: "#E99923",
            marginLeft: "4px",
            fontFamily: "sundayAfternoon",
          }}
        >
          {"TikTok"}
        </Button>
      </Col>
    </PageLayout>
  );
};
